import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { formDataConverter } from '../../_helpers/formDataConverter';
import { setTokens } from '../../_helpers/localStorage';
import { RootState } from '../../app/store';
import {
  getFacilityRequestParams,
  IFacilityParams,
} from '../../components/header/facilityParams';
import { closeModalReducer } from '../../components/modal/modalSlice';
import {
  authenticate,
  // eslint-disable-next-line prettier/prettier
  settingsApi,
} from '../../services/authenticationService';
import { getImageId } from '../../services/facilityService';
import {
  addNewFacility,
  addSingleTranslataion,
  addTranslataion,
  getAuthUser,
  getFacilities,
  // eslint-disable-next-line prettier/prettier
  getFacility,
} from '../../services/getUserService';
import {
  IActivitiesFilter,
  IActivityPaginate,
} from '../activitiesPage/activitiesInterface';
import { IAddFacilityPayload } from '../registrationPage/registrationTypes';
import {
  IFacility,
  IFacilityAddress,
  ILogin,
  IRoleIds,
  // eslint-disable-next-line prettier/prettier
  IUser,
} from './loginInterface';

export interface IAuthentication {
  isProcessingLogin: boolean;
  isProcessingUser: boolean;
  isProcessingFacilities: boolean;
  isRedirectingHome: boolean;
  isProcessingFacility: boolean;
  isProcessingRoleIds: boolean;
  fetchedFacility: boolean;
  accessToken?: string;
  loggedInUser: IUser;
  currentFacilities: IFacility[];
  currentFacility: IFacility;
  activeFacility: IFacility;
  roleIds: IRoleIds;
  filter: IActivitiesFilter;
  facilityPaginate: IActivityPaginate;
  isProcessingNewFacility: boolean;
  isFetchingNewFacilityImageId: boolean;
  isDataTranslating: boolean;
}

const initialState: IAuthentication = {
  isProcessingLogin: false,
  isProcessingUser: false,
  isProcessingFacilities: false,
  isRedirectingHome: false,
  isProcessingFacility: false,
  isProcessingRoleIds: false,
  fetchedFacility: false,
  loggedInUser: {
    id: '',
    name: '',
    userName: '',
    email: '',
    phoneNo: '',
    address: '',
    dob: '',
    actor: '',
    roleId: '',
  },
  currentFacilities: [],
  activeFacility: {
    id: '',
    email: '',
    phoneNo: '',
    facilityAddress: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
    },
    facilityId: '',
    facilityName: '',
    facilityNumber: '',
    status: '',
    whiteLogoId: '',
    coloredLogoId: '',
    coverPicId: '',
    location: {
      longitude: '',
      latitude: '',
    },
  },
  currentFacility: {
    id: '',
    email: '',
    phoneNo: '',
    facilityAddress: {} as IFacilityAddress,
    facilityId: '',
    facilityName: '',
    facilityNumber: '',
    status: '',
    whiteLogoId: '',
    coloredLogoId: '',
    coverPicId: '',
    location: {
      longitude: '',
      latitude: '',
    },
  },
  filter: {
    sort: 'createdAt,Desc',
    page: 0,
    size: 20,
  },
  facilityPaginate: {
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    size: 0,
    number: 0,
  },
  roleIds: {} as IRoleIds,
  isProcessingNewFacility: false,
  isFetchingNewFacilityImageId: false,
  isDataTranslating: false,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    resetAuthState: () => {
      return initialState;
    },
    changePageFilter: (state, action) => {
      state.filter.page = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.isProcessingLogin = true;
        state.isRedirectingHome = true;
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.isProcessingLogin = false;
        state.isRedirectingHome = true;
        if (action.payload.data) {
          setTokens(action.payload.data[0]?.accessToken);
        } else {
          toast.error('User not Authenticated');
          state.isProcessingLogin = false;
        }
        // toast.success(`${action.payload?.message.toUpperCase()}`);
      })
      .addCase(
        authenticateUser.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingLogin = false;
          state.isRedirectingHome = false;
          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(getAuthenticatedUser.pending, (state) => {
        state.isProcessingLogin = true;
        state.isProcessingUser = true;
        state.isRedirectingHome = true;
      })
      .addCase(getAuthenticatedUser.fulfilled, (state, action) => {
        state.isProcessingLogin = false;
        state.isProcessingUser = false;
        state.isRedirectingHome = true;
        state.loggedInUser = action.payload?.data[0];
      })
      .addCase(getAuthenticatedUser.rejected, (state) => {
        state.isProcessingLogin = false;
        state.isProcessingUser = false;
        state.isRedirectingHome = false;
      })
      .addCase(getAllFacilities.pending, (state) => {
        state.isProcessingFacilities = true;
        state.isRedirectingHome = true;
        state.currentFacilities = [];
      })
      .addCase(getAllFacilities.fulfilled, (state, action) => {
        state.isProcessingFacilities = false;
        state.isRedirectingHome = false;
        state.facilityPaginate = action.payload?.data[0];
        state.currentFacilities = action.payload?.data[0]?.data;
        state.activeFacility = action.payload?.data[0]?.data[0];
      })
      .addCase(getAllFacilities.rejected, (state) => {
        state.isProcessingFacilities = false;
        state.currentFacilities = [];
      })
      .addCase(getSelectedFacility.pending, (state) => {
        state.isProcessingFacility = true;
        state.fetchedFacility = false;
      })
      .addCase(getSelectedFacility.fulfilled, (state, action) => {
        state.isProcessingFacility = false;
        state.fetchedFacility = true;
        state.currentFacility = action.payload?.data[0];
      })
      .addCase(getSelectedFacility.rejected, (state) => {
        state.isProcessingFacility = false;
        state.fetchedFacility = false;
      })
      .addCase(getRolesIds.pending, (state) => {
        state.isProcessingRoleIds = true;
      })
      .addCase(getRolesIds.fulfilled, (state, action) => {
        state.isProcessingRoleIds = false;
        state.roleIds = action.payload?.data[0];
      })
      .addCase(getRolesIds.rejected, (state, action: PayloadAction<any>) => {
        state.isProcessingRoleIds = false;

        toast.error(
          `${action.payload
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload.slice(1)}`
        );
      })
      .addCase(addNewFacilityAction.pending, (state) => {
        state.isProcessingNewFacility = true;
      })
      .addCase(addNewFacilityAction.fulfilled, (state, action) => {
        state.isProcessingNewFacility = false;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        addNewFacilityAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingNewFacility = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(addNewFacilityActionWithImage.pending, (state) => {
        state.isFetchingNewFacilityImageId = true;
      })
      .addCase(addNewFacilityActionWithImage.fulfilled, (state, action) => {
        state.isFetchingNewFacilityImageId = false;
      })
      .addCase(
        addNewFacilityActionWithImage.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingNewFacilityImageId = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(translateText.pending, (state) => {
        state.isDataTranslating = true;
      })
      .addCase(translateText.fulfilled, (state) => {
        state.isDataTranslating = false;
      })
      .addCase(translateText.rejected, (state) => {
        state.isDataTranslating = false;
      });
  },
});

export const authenticateUser = createAsyncThunk(
  'authentication/authenticateUser',
  async (userData: ILogin, { rejectWithValue }) => {
    try {
      const response = await authenticate(userData);
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const getRolesIds = createAsyncThunk(
  'authentication/getRolesIds',
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await settingsApi();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const getAuthenticatedUser = createAsyncThunk(
  'authentication/getAuthenticatedUser',
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAuthUser();
      dispatch(getRolesIds(null));
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const getAllFacilities = createAsyncThunk(
  'authentication/getAllFacilities',
  async (data: IFacilityParams, thunkAPI) => {
    try {
      const params = getFacilityRequestParams(data.sort, data.page, data.size);
      const response = await getFacilities(params);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.message);
    }
  }
);

export const getSelectedFacility = createAsyncThunk(
  'authentication/getSelectedFacility',
  async (id: string, thunkAPI) => {
    try {
      const response = await getFacility(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.message);
    }
  }
);

export const addNewFacilityAction = createAsyncThunk(
  'authentication/addNewFacilityAction',
  async (
    data: IAddFacilityPayload,
    { rejectWithValue, dispatch, getState }
  ) => {
    const { authentication } = getState() as {
      authentication: IAuthentication;
    };
    try {
      const response = await addNewFacility(data);
      dispatch(closeModalReducer());
      dispatch(getAllFacilities(authentication?.filter));
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const translateText = createAsyncThunk(
  'authentication/translateText',
  async (data: any, { rejectWithValue }) => {
    try {
      console.log('data', data);

      const response = await addTranslataion(data);

      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const translateSingleText = createAsyncThunk(
  'authentication/translateText',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await addSingleTranslataion(data);

      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const addNewFacilityActionWithImage = createAsyncThunk(
  'authentication/addNewFacilityActionWithImage',
  async (data: IAddFacilityPayload, { rejectWithValue, dispatch }) => {
    try {
      const imageResponse = await getImageId(formDataConverter(data.coverPic));
      dispatch(
        addNewFacilityAction({
          ...data,
          coverPicId: imageResponse?.data[0]?.id,
        })
      );
      return imageResponse;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const { resetAuthState, changePageFilter } = authenticationSlice.actions;
export const selectAuthentication = (state: RootState) => state.authentication;
export const authenticationReducer = authenticationSlice.reducer;
